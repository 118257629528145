/* global tw */
import React from 'react'
import ProjectCard from '../components/ProcessCard';
import Layout from '../components/Layout';
import styled from 'react-emotion';
import { rotate } from '../styles/animations';
import triangle from '../images/triangle.svg';


const Title = styled.h1`
  ${tw('text-4xl lg:text-4xl font-serif text-white mb-8 tracking-wide relative inline-block')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    animation: ${rotate} 4s linear infinite;
    left: -60px;
    top: 5px;
  }
`;

const ProjectsWrapper = styled.div`
  ${tw('flex flex-wrap justify-between mt-8')};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const Projects = (props) => (
  <Layout>
    <Title>Services</Title>
      <ProjectsWrapper>
        <ProjectCard
            title="Website Design"
            bg="linear-gradient(to right, #2b5876 0%, #4e4376 100%)"
          >
            We build custom websites from scratch that wow visitors and drive
            conversions. We like working with small to mid-sized businesses
            who want a website that builds your brands image, looks
            incredible, and generates real, measurable results.
          </ProjectCard>
          <ProjectCard
            title="Social Media Management"
            bg="linear-gradient(to right, #000428 0%, #004e92 100%)"
          >
            We offer full-service social media management services. We can
            create and maintain your marketing campaigns - bringing your
            business in front of thousands or even tens of thousands of
            potential new customers
          </ProjectCard>
          <ProjectCard
            title="Search Engine Optimization"
            bg="linear-gradient(to right, #aa076b 0%, #61045f 100%)"
          >
            Get found on Google & Bing for relevant searches with our SEO
            services. Through careful keyword research and white-hat
            practices, we can help you achieve high rankings in the major
            search engines.
          </ProjectCard>
          <ProjectCard
            title="Facebook Advertising"
            bg="linear-gradient(to right, #141e30 0%, #243b55 100%)"
          >
            Facebook is the largest and most powerful social media advertising
            platform in the world. At Durian, we realize how to put the
            correct sorts of ads in the correct places on Facebook and make
            incredible outcomes for your business.
          </ProjectCard>
        </ProjectsWrapper>
    </Layout>
);

export default Projects;